<template>
    <div id="auth"></div>
</template>
<script>
    import { requestUser } from '@/utils/api'
    import { handleTicket } from '@/utils/tools'
    export default {
        beforeMount: function () {
            requestUser().then(res => {
                handleTicket()
            }).catch(err => {
                window.location.href = '/'
            })
        }
    }
</script>