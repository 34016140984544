<template>
  <el-container style="height:100%;">
    <Auth />
    <el-header>
      <Header></Header>
    </el-header>
    <el-container class="admin-content" style="top:60px">
      <!-- 侧边栏 -->
      <el-aside class="aside">
        <el-scrollbar>
          <el-menu
            style="height:100%"
            :uniqueOpened="true"
            class="admin-menu-vertical"
            router
            text-color="#595959"
            active-text-color="#227FFF"
            :collapse-transition="true"
            :default-active="routerActive"
            @select="handleSelectNav"
          >
            <template v-for="item in routesArr">
              <!-- 二级菜单 -->
              <el-submenu
                :index="'/admin/' + item.path"
                :key="item.name"
                v-if="item.children && item.children.length > 0"
              >
                <template #title
                  ><i :class="item.meta.icon"></i
                  >{{ item.meta.title }}</template
                >
                <el-menu-item-group>
                  <template v-for="items in item.children">
                    <el-menu-item
                      :index="item.path + '/' + items.path"
                      :key="items.name"
                      v-if="!items.meta.hidden"
                    >
                      <template #title>
                        <i :class="items.meta.icon"></i>
                        {{ items.meta.title }}
                      </template>
                    </el-menu-item>
                  </template>
                </el-menu-item-group>
              </el-submenu>
              <!-- 普通一级菜单 -->
              <el-menu-item
                :index="'/admin/' + item.path"
                :key="item.name"
                v-else
              >
                <i :class="item.meta.icon"></i
                ><template #title>{{ item.meta.title }}</template>
              </el-menu-item>
            </template>
          </el-menu>
        </el-scrollbar>
      </el-aside>
      <!-- 主区域 -->
      <el-main style="" class="admin-main">
        <el-scrollbar>
          <!-- <el-row>
            <Breadcrumb></Breadcrumb>
          </el-row> -->
          <router-view />
        </el-scrollbar>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Header from "@/components/Header.vue";
import router from "@/router/index.js";
import Auth from "@/components/Auth.vue";
// import Breadcrumb from "@/views/Admin/components/Breadcrumb";
import {
  defineComponent,
  reactive,
  onBeforeMount,
  onMounted,
  toRefs,
  computed,
} from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
  components: { Header, Auth },
  setup() {
    const { name, path } = useRoute();
    const {
      options: { routes },
    } = router;
    const state = reactive({
      // 默认展示页面
      routerActive: "/admin/apps/list",
      // 生成路由表
      routesArr: computed(() => {
        return routes.filter((v) => {
          return v.name === "Admin";
        })[0]["children"];
      }),
      handleSelectNav(key, keyPath) {
        console.log(key, keyPath);
      },
    });
    onBeforeMount(() => {
      console.log(path, routes, state.routesArr);
      state.routerActive = path;
      if (name == "appEdit" || name == "appAdd") {
        state.routerActive = "/admin/apps/list";
      }
    });
    onMounted(() => {});
    const refData = toRefs(state);
    return {
      ...refData,
    };
  },
});
</script>

<style lang="scss" scoped>
// 头部
.el-header {
  background-color: #fff !important;
  padding: 0;
  color: #fff;
  border-bottom: 1px solid #efe8e8;
  line-height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: 74px;
  background: #ffffff;
  box-shadow: 0px 0px 35px 0px rgba(214, 222, 225, 0.68);
}
// 主内容区
.admin-content {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  z-index: 11;
  // 侧边栏样式
  .el-aside {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 250px !important;
  }
  .admin-main {
    background-color: #f8f8f8;
    left: 250px;
    width: calc(100% - 250px);
  }
}

.aside {
  // &::v-deep(.el-menu-item.is-acitve) {
  //   color: #227fff !important;
  //   border-right: 3px solid #227fff !important;
  // }
  &::v-deep(.el-menu-item) {
    font-size: 18px;
    font-family: SourceRegular;
    font-weight: 400;
    border-right: 3px solid transparent !important;
    transition: all 0s;
    &:hover {
      background: #f6f7fa;
    }
    &.is-active {
      border-right: 3px solid #227fff !important;
      background-color: #227fff17;
    }
  }
  &::v-deep(.el-submenu) {
    .el-submenu__title {
      font-size: 18px;
      font-family: SourceRegular;
      font-weight: 400;
      &:hover {
        background: #f6f7fa;
        .el-submenu__icon-arrow {
          font-size: 14px;
          border: 1px solid transparent;
          background-color: #227fff;
          color: #fff;
          border-radius: 2px;
          transition: background-color 0.2s ease-in-out;
        }
      }
    }
  }
}
// 主内容
.admin-main {
  height: 100%;
  position: absolute;
}
</style>
